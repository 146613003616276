import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from '../common.slice';

const initialState = {
  existingFileLoading: false,
  isExistingFileUpdated: false,
  // getExistingFile: [],
};

// export const getExistingFileListData = createAsyncThunk(
//   'admin/get-existing-file-list',
//   (file_type, { dispatch }) => {
//     return new Promise((resolve, reject) => {
//       axios
//         .post('api/existing_file', file_type)
//         .then(res => {
//           if (res.data.err === 0) {
//             if (res.data.data.length > 0) {
//               resolve(res.data.data);
//             } else {
//               resolve([]);
//             }
//           } else {
//             reject();
//           }
//         })
//         .catch(error => {
//           dispatch(showMessage({ message: error.response.data.msg }));
//           reject(error);
//         });
//     });
//   },
// );

export const existingFileSlice = createSlice({
  name: 'existingFile',
  initialState,
  reducers: {
    setExistingFileLoading: (state, action) => {
      state.existingFileLoading = action.payload;
    },
    setIsExistingFileUpdated: (state, action) => {
      state.isExistingFileUpdated = action.payload;
    },
    setExistingFileDetail: (state, action) => {
      state.productCategoryDetail = action.payload;
    },
  },
  extraReducers: {
    // [getExistingFileListData.pending]: state => {
    //   state.existingFileLoading = true;
    // },
    // [getExistingFileListData.rejected]: state => {
    //   state.getExistingFile = [];
    //   state.existingFileLoading = false;
    // },
    // [getExistingFileListData.fulfilled]: (state, action) => {
    //   state.getExistingFile = action.payload || [];
    //   state.existingFileLoading = false;
    // },
  },
});

export const {
  setExistingFileLoading,
  setIsExistingFileUpdated,
  setExistingFileDetail,
} = existingFileSlice.actions;

export default existingFileSlice.reducer;
